.container {
  display: flex;
  flex-direction: column;

  section {
    flex: 1;
    padding-top: 48px;
    padding-left: 64px;
  }

  .title {
    color: var(--palette-text-secondary);
    padding-top: 28px;
  }
}

.cards {
  padding: 16px 0;
}

.experts {
  display: flex;
  flex-direction: column;
  cursor: pointer;

  button {
    justify-content: start;
    padding: 8px;
  }

  .avatars {
    padding: 8px 24px;
    max-width: max-content;
  }

  .text {
    color: var(--palette-text-secondary);
    margin: 0 8px 8px;
  }
}

.percentage {
  display: flex;
  flex-direction: row;
  padding: 24px 0;

  .progress {
    margin-right: 16px;
    height: 52px;
  }
}

.connectText {
  cursor: pointer;
  .ChevronIcon {
    font-size: 16px;
    margin-left: 4px;
    margin-bottom: -3px;
  }
}

.rightSideDivider {
  margin-top: 24px;
  margin-bottom: -32px;
}

.divider {
  margin: 24px 0px;
}

.stepperTitle {
  margin-bottom: 16px;
  margin-left: 16px;
}

.mobileStepperTitle {
  margin-top: 20px;
  margin-bottom: 12px;
}

.stepperContainer {
  position: sticky;
  top: 80px;
  @media (--viewport-md) {
    position: relative;
  }
}

.mobileStepperContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.gAppDivider {
  margin-top: 8px;
  margin-bottom: 16px;
}

.gAppButton {
  display: flex;
  cursor: pointer;
  align-items: center;
  .chevronIcon {
    font-size: 16px;
    margin-left: 4px;
  }
  .mobileIcon {
    margin-right: 4px;
    font-size: 32px;
    color: var(--palette-text-primary);
    rect {
      stroke: var(--palette-text-primary);
    }
  }
}
